<template>
  <div class="product" v-if="currentProduct">
      <div class="product_flex">
        <h3 class="product__name">{{currentProduct.produktName}}</h3>  
        <div class="product_flex_flexbox1" >
          <img class="product__image" alt="Shirt Preview" v-bind:src="getImageUrlOfProduct(currentProduct)"> 
          <img v-on:click="roateImage()" v-if="currentProduct.produktBild.length > 1" class="product__rotate" alt="Rotate" src="@/assets/images/icons/rotate_icon.png">
        </div>
        <div class="product_flex_flexbox2"> 
          <h3 class="product__name_desktop">{{currentProduct.produktName}}</h3>
          <div class="product__infos_flex">
              <div class="product__infos product__infos_price"> 
                <span class="product__size">Grösse: {{convertUppercaseString(currentProduct.grosse)}}</span>
                <span class="product__price">{{currentProduct.preis}}.00 €</span>
              </div>
              <div class="product__infos  product__infos_info">
                <span class="product__info"><span class="text_small">Kategorie:</span> {{convertUppercaseString(currentProduct.kategorie)}}</span>
                <span class="product__info"><span class="text_small">Hersteller:</span> {{convertUppercaseString(currentProduct.produktHersteller)}}</span>
                <span class="product__info"><span class="text_small">Zustand:</span> {{convertUppercaseString(currentProduct.zustand)}}</span>
                <span class="product__info"><span class="text_small">Lieferzeit:</span> 2-3 Wochen</span>
              </div>

              <div class="product__infos product__infos_price_desktop"> 
                <span class="product__size">Grösse: {{convertUppercaseString(currentProduct.grosse)}}</span>
                <span class="product__price">{{currentProduct.preis}}.00 €</span>
              </div>

              <div class="product__detailbutton_outer">
                <div class="product__detailbutton button shadow-box" v-on:click="addToBasket()">
                  In den Warenkorb 
                </div>
              </div>

          </div>
        </div>
      </div>

      

    <div class="product__infos">
          <router-link class="product__back_link link_secondary link_secondary_back" to="/shop">
            <span>Zum Shop</span>
        </router-link>
        <router-link class="product__basket_link link_secondary" to="/basket">
            <span>Zum Warenkorb</span>
        </router-link>
    </div>
  </div>
</template>

<script>
import store from '@/store/store.js';
import apiService from '@/services/apiService.js';

export default {
  name: 'Product',
  components: {
  },
  data() {
    return {
      store,
      imageIndex : 0,
    };
  },
  computed: {
    currentProduct: function () {
      return this.store.getCurrentSelectedproduct();
    },
  },
  methods: {
    convertUppercaseString(string){
      var result;
      if(string == null){
        result = "unbekannt";
      }else{
        if(string=="wieneu"){
          string = "wie neu";
        }
        if(string=="youthm"){
          string = "youth m";
        }
        if(string=="youthl"){
          string = "youth l";
        }
        if(string=="ca23Wochen"){
          string = "ca. 2-3 wochen";
        }
        result = string.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2");
      }
      return result;
    },
    roateImage(){
      if(this.imageIndex == 0){
        this.imageIndex = 1;
      }else if(this.imageIndex == 1){
        this.imageIndex = 0;
      }
      console.log(this.imageIndex);
    },
    addToBasket(){
      if (store.userData.products.indexOf(this.currentProduct) === -1){
        apiService.checkReserveProduct(this.currentProduct);
      }else{
        store.firePopup = {
          title: "Schon geshoppt!",
          text: "Dieser Artikel wurde schon hinzugefügt.",
          type: "error",
          timer: 300000
        };
      }
    },
    getImageUrlOfProduct (product) {
      var productUrl = "";
      if(product.produktBild.length == 0){
        productUrl = product.produktBildUrl;
      }else{
        productUrl = product.produktBild[this.imageIndex].url;
      }
      return productUrl;
    },
  }
}
</script>

<style lang="scss">
  .product{
    padding-top: 130px;
    margin-bottom: 50px;
    position: relative;
    max-width: 900px;
    color: $color-text;

    &__rotate{
      position: absolute;
      width: 27px;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    @media only screen and (min-width: 612px) {
          background-color: $color-white;
          padding: 0 16 16 16;
          max-width: 1300px;
        }

    &_flex {
      display: flex;
      flex-direction: column;
      margin: 0 24px 0 24px;

      @media only screen and (min-width: 480px) {
        margin: 0 48px 0 48px;
      }
    
      @media only screen and (min-width: 612px) {
      margin: 0 24px 0 24px;
          flex-direction: row;
        }

        @media only screen and (min-width: 800px) {
        margin: 0 48px 0 48px;
      }

      @media only screen and (min-width: 1200px) {
        justify-content: center;
      }

      

      &_flexbox1 {
        position: relative;
        width: 100%;
        @media only screen and (min-width: 612px) {
          width: 50%;
        }
      }

      &_flexbox2 {
        max-width: 480px;
        
        @media only screen and (min-width: 612px) {
          width: 48%;
          margin-right: 32px;
        }

        @media only screen and (min-width: 800px) {
          padding-top: 64px;
          margin-left: 24px;
          margin-right: 48px;
        }

        @media only screen and (min-width: 1200px) {
          width: 35%;
          max-width: 560px;
          padding-top: 64px;
          margin-left: 64px;
        }

      }
    }
    
    &_infos_flex {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      
    }

    &__name{
      font-size: 42px;
      line-height: 39px;
      padding: 0 0 16px 0;
      @media only screen and (min-width: 612px) {
        display: none;
      }
    }

    &__name_desktop {
      font-size: 56px;
      line-height: 1em;
      display: none;
      @media only screen and (min-width: 612px) {
        display: block;
      }
    }
    
    &__loader{
      display: none;
      position: relative;
      width: 200px;
      left: 50%;
      top: 50%;
    
    }
    &__image{
      position: relative;
      width: 100%;
      margin-bottom: 24px;
      margin-top: 60px;
      @media only screen and (min-width: 612px) {
        max-width: 360px;
      }

      @media only screen and (min-width: 1200px) {
        max-width: 420px;
      }

       @media only screen and (max-width: 800px) {
        margin-top: 30px;
      }

      @media only screen and (max-width: 612px) {
        margin-top: 0px;
      }
      
      
      &_outer{
        overflow: hidden;
        position: relative;
        min-height: 280px;
        width: 100%;
        
        @media only screen and (min-width: 612px){ 
          width: 50%;
          display: block;
        }
        @media only screen and (min-width: 1024px) {
          margin-bottom: 48px;
        }
        


      }
    }
    &__infos{
      position: relative;
      display: block;
      min-height: 26px;
      margin-bottom: 12px;
      padding-bottom: 20px;
      
     &_outer{
        display: block;
        position: relative;
        @media only screen and (min-width: 612px){ 
          width: 50%;
          display: block;
          position: relative;
          right: 0;
          top: 0;
        }
      }
      &_price{
      display: flex;
      flex-direction: row;
         
        @media only screen and (min-width: 612px){ 
          display: none;
          
        }
      }

      &_price_desktop{
      display: none;
      
      @media only screen and (min-width: 612px){
      display: flex;
      flex-direction: row;
      padding: 0;
      top: 0;
      left: 32px;
      right: 32px;
      }
      
      }

      &_info{
          margin-bottom: 24px;
          font-size: 18px;

        @media only screen and (min-width: 612px){ 
          position: relative;
          width: 100%;
          padding: 0;
          top: 0px;
          left: 32px;
          right: 32px;

          
        }
      }
    }
    &__size{
      width: 50%;
      font-size: 22px;
      font-family: 'Bebas Neue', sans-serif;    
      text-transform: uppercase;
      font-weight: normal;
      text-align: left;
      widows: 50%;
      padding-top: 14px;
      
      @media only screen and (min-width: 612px){ 
        left: 0;
      }
    }
    &__price{
      font-size: 40px;
      font-family: 'Bebas Neue', sans-serif;    
      text-transform: uppercase;
      font-weight: normal;
      text-align: right;
      width: 50%;
      
       @media only screen and (min-width: 612px){ 
         position: relative;
        right: 0;
      }
    }
    &__info{
      color: #000;
      font-size: 21px;
      font-family: 'Bebas Neue', sans-serif;    
      text-transform: uppercase;
      font-weight: normal;
      text-align: left;
      position: relative;
      display: block;
      border-bottom: 1px solid #999;
      padding-bottom: 4px;
      margin-bottom: 8px;
      letter-spacing: 0.2;
      @media only screen and (min-width: 1024px) {
        font-size: 24px;
      }
      
    }
    &__detailbutton{
      width: 220px;
      text-align: center;
      
      @media only screen and (min-width: 612px){ 
        display: block;
        position: relative;
        top: 0;
      }

      &_outer{
        margin: auto;
        margin-top: 32px;
        margin-bottom: 32px;
        width: 220px;

         @media only screen and (min-width: 612px){ 
          margin-left: 32px;
        }

        @media only screen and (min-width: 1024px){ 
          left: 30%;
        }
      }
    }
    &__back_link{
      position: absolute;
      display: block;
      left: 16px;
      top: 5px;
      width: 110px;
      background-position: 0px 16px;
      padding-left: 40px!important;

      @media only screen and (min-width: 1024px) {
        left: 48px;
      }
    }
    &__basket_link{
      position: absolute;
      display: block;
      right: 16px;
      top: 5px;
      width: 110px;
      background-position: 118px 16px;
    }
  }
</style>